var setGlobalAjaxHeader;

(function()
{
	var headers															= {};

	setGlobalAjaxHeader = function(key, value)
	{
		if (value)
		{
			headers[key]												= value;
		}
		else
		{
			headers[key]												= undefined;
			delete headers[key];
		}
	};

	// Overrules all AJAX calls globally, regardless of which library is behind the request.

	var open															= XMLHttpRequest.prototype.open;
	var send															= XMLHttpRequest.prototype.send;
	var setRequestHeader												= XMLHttpRequest.prototype.setRequestHeader;

	// Simple cookie implementation to avoid conflicts with other libraries.
	var getCookie = function(name)
	{
		var nameEQ														= name + '=';
		var ca															= document.cookie.split(';');
		var c;

		for(var i = 0; i < ca.length; i++)
		{
			c															= ca[i];
			while (c.charAt(0) === ' ')									{ c = c.substring(1,c.length); }
			if (c.indexOf(nameEQ) === 0)								{ return c.substring(nameEQ.length, c.length); }
		}

		return null;
	};

	XMLHttpRequest.prototype.setRequestHeader = function(key, value)
	{
		if (!this._headers)												{ this._headers = {}; }
		this._headers[key]												= value;

		return setRequestHeader.apply(this, arguments);
	};

	XMLHttpRequest.prototype.open = function(method, url)
	{
		this._url														= url;
		if (!this._headers)												{ this._headers = {}; }

		return open.apply(this, arguments);
	};

	XMLHttpRequest.prototype.send = function(data)
	{
		if (!(/^http:.*/.test(this._url) || /^https:.*/.test(this._url)))
		{
			// Add X-Requested-With to all local URIs, if it was not added already.
			if (!this._headers['X-Requested-With'])						{ this.setRequestHeader('X-Requested-With', 'XMLHttpRequest'); }

			// Add X-XSRF-Token if provided as a cookie, if it was not added already.
			var token													= getCookie('XSRF-TOKEN');

			if (token && !this._headers['X-XSRF-TOKEN'])				{ this.setRequestHeader('X-XSRF-TOKEN', token); }

			for (var key in headers)									{ this.setRequestHeader(key, typeof(headers[key]) == 'function' ? headers[key]() : headers[key]); }
		}

		return send.apply(this, arguments);
	};
})();

// Production steps of ECMA-262, Edition 5, 15.4.4.19
// Reference: http://es5.github.io/#x15.4.4.19
if (!Array.prototype.map) {

  Array.prototype.map = function(callback, thisArg) {

    var T, A, k;

    if (this == null) {
      throw new TypeError(' this is null or not defined');
    }

    // 1. Let O be the result of calling ToObject passing the |this| 
    //    value as the argument.
    var O = Object(this);

    // 2. Let lenValue be the result of calling the Get internal 
    //    method of O with the argument "length".
    // 3. Let len be ToUint32(lenValue).
    var len = O.length >>> 0;

    // 4. If IsCallable(callback) is false, throw a TypeError exception.
    // See: http://es5.github.com/#x9.11
    if (typeof callback !== 'function') {
      throw new TypeError(callback + ' is not a function');
    }

    // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
    if (arguments.length > 1) {
      T = thisArg;
    }

    // 6. Let A be a new array created as if by the expression new Array(len) 
    //    where Array is the standard built-in constructor with that name and 
    //    len is the value of len.
    A = new Array(len);

    // 7. Let k be 0
    k = 0;

    // 8. Repeat, while k < len
    while (k < len) {

      var kValue, mappedValue;

      // a. Let Pk be ToString(k).
      //   This is implicit for LHS operands of the in operator
      // b. Let kPresent be the result of calling the HasProperty internal 
      //    method of O with argument Pk.
      //   This step can be combined with c
      // c. If kPresent is true, then
      if (k in O) {

        // i. Let kValue be the result of calling the Get internal 
        //    method of O with argument Pk.
        kValue = O[k];

        // ii. Let mappedValue be the result of calling the Call internal 
        //     method of callback with T as the this value and argument 
        //     list containing kValue, k, and O.
        mappedValue = callback.call(T, kValue, k, O);

        // iii. Call the DefineOwnProperty internal method of A with arguments
        // Pk, Property Descriptor
        // { Value: mappedValue,
        //   Writable: true,
        //   Enumerable: true,
        //   Configurable: true },
        // and false.

        // In browsers that support Object.defineProperty, use the following:
        // Object.defineProperty(A, k, {
        //   value: mappedValue,
        //   writable: true,
        //   enumerable: true,
        //   configurable: true
        // });

        // For best browser support, use the following:
        A[k] = mappedValue;
      }
      // d. Increase k by 1.
      k++;
    }

    // 9. return A
    return A;
  };
}

// Production steps of ECMA-262, Edition 5, 15.4.4.14
// Reference: http://es5.github.io/#x15.4.4.14
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function(searchElement, fromIndex) {

    var k;

    // 1. Let o be the result of calling ToObject passing
    //    the this value as the argument.
    if (this == null) {
      throw new TypeError('"this" is null or not defined');
    }

    var o = Object(this);

    // 2. Let lenValue be the result of calling the Get
    //    internal method of o with the argument "length".
    // 3. Let len be ToUint32(lenValue).
    var len = o.length >>> 0;

    // 4. If len is 0, return -1.
    if (len === 0) {
      return -1;
    }

    // 5. If argument fromIndex was passed let n be
    //    ToInteger(fromIndex); else let n be 0.
    var n = +fromIndex || 0;

    if (Math.abs(n) === Infinity) {
      n = 0;
    }

    // 6. If n >= len, return -1.
    if (n >= len) {
      return -1;
    }

    // 7. If n >= 0, then Let k be n.
    // 8. Else, n<0, Let k be len - abs(n).
    //    If k is less than 0, then let k be 0.
    k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

    // 9. Repeat, while k < len
    while (k < len) {
      // a. Let Pk be ToString(k).
      //   This is implicit for LHS operands of the in operator
      // b. Let kPresent be the result of calling the
      //    HasProperty internal method of o with argument Pk.
      //   This step can be combined with c
      // c. If kPresent is true, then
      //    i.  Let elementK be the result of calling the Get
      //        internal method of o with the argument ToString(k).
      //   ii.  Let same be the result of applying the
      //        Strict Equality Comparison Algorithm to
      //        searchElement and elementK.
      //  iii.  If same is true, return k.
      if (k in o && o[k] === searchElement) {
        return k;
      }
      k++;
    }
    return -1;
  };
}

// Production steps of ECMA-262, Edition 5, 15.4.4.15
// Reference: http://es5.github.io/#x15.4.4.15
if (!Array.prototype.lastIndexOf) {
  Array.prototype.lastIndexOf = function(searchElement /*, fromIndex*/) {
    'use strict';

    if (this === void 0 || this === null) {
      throw new TypeError();
    }

    var n, k,
      t = Object(this),
      len = t.length >>> 0;
    if (len === 0) {
      return -1;
    }

    n = len - 1;
    if (arguments.length > 1) {
      n = Number(arguments[1]);
      if (n != n) {
        n = 0;
      }
      else if (n != 0 && n != (1 / 0) && n != -(1 / 0)) {
        n = (n > 0 || -1) * Math.floor(Math.abs(n));
      }
    }

    for (k = n >= 0 ? Math.min(n, len - 1) : len - Math.abs(n); k >= 0; k--) {
      if (k in t && t[k] === searchElement) {
        return k;
      }
    }
    return -1;
  };
}

//if (!Array.prototype.group)
//{
//	Array.prototype.group = function()
//	{
//		var res								= [];
//		var sorted							= this.sort();
//		var start, end;
//		var t1, t2;
//
//		for (var i = 0; i < sorted.length; i = i + 1)
//		{
//			if (!start)
//			{
//				start						= sorted[i];
//				end							= sorted[i];
//				continue;
//			}
//			else
//			{
//				if (typeof(sorted[i]) == 'number')
//				{
//					if ((sorted[i - 1] + 1) === sorted[i])
//					{
//						end					= sorted[i];
//						continue;
//					}
//				}
//				else if (typeof(sorted[i]) == 'string' && typeof(sorted[i - 1]) == 'string' && sorted[i].length == sorted[i - 1].length)
//				{
//					t1						= sorted[i - 1].substring(sorted[i - 1].length - 1).charCodeAt(0);
//					t2						= sorted[i].substring(sorted[i].length - 1).charCodeAt(0);
//					
//					if ((t1 + 1) === t2)	{ end = sorted[i]; continue; }
//				}
//			}
//			
//			res.push({ 'start': start, 'end': end });
//			start							= sorted[i];
//		}
//		
//		if (start && end)					{ res.push({ 'start': start, 'end': end }); }
//
//		return res;
//	}
//}

(function()
{
	var regexpSpecialChars																		= /([\[\]\^\$\|\(\)\\\+\*\?\{\}\=\!])/gi;

	String.prototype.literal = function()
	{
		return this.replace(regexpSpecialChars, "\\$1");
	};

	String.prototype.startsWith = function(str)
	{
		return (this.match("^" + str.literal()) == str);
	};

	String.prototype.endsWith = function(str)
	{
		return (this.match(str.literal() + "$") == str);
	};

	String.prototype.left = function(str)
	{
		var i																					= this.indexOf(str);
		if (i > -1)																				{ return this.substring(0, i); }
		else																					{ return this; }
	};

	String.prototype.right = function(str)
	{
		var i																					= this.indexOf(str);
		if (i > -1)																				{ return this.substring(i + str.length); }
		else																					{ return this; }
	};

	String.prototype.backwardsLeft = function(str)
	{
		var i																					= this.lastIndexOf(str);
		if (i > -1)																				{ return this.substring(0, i); }
		else																					{ return this; }
	};

	String.prototype.backwardsRight = function(str)
	{
		var i																					= this.lastIndexOf(str);
		if (i > -1)																				{ return this.substring(i + str.length); }
		else																					{ return this; }
	};

	String.prototype.matches = function(m)
	{
		var res																					= this.match(m);

		return res && res.length;
	}

	String.prototype.toMoment = function(options)
	{
		if (moment)
		{
			if (!options)																		{ options = {}; }

			var format, precision;

			if		(this.matches(/\d{2}-\d{2}-\d{4}T\d{2}:\d{2}:\d{2}(Z|[\+\-]\d{2}:\d{2})/))	{ format = 'DD-MM-YYYY\'T\'HH:mm:ssZ'; precision = 3; } // ISO_OFFSET_DATE_TIME, dutch format
			else if (this.matches(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[\+\-]\d{2}:\d{2})/))	{ format = 'YYYY-MM-DD\'T\'HH:mm:ssZ'; precision = 3; } // ISO_OFFSET_DATE_TIME
			else if	(this.matches(/\d{2}-\d{2}-\d{4}T(Z|\+\d{2}:\d{2})/))						{ format = 'DD-MM-YYYYZ'; precision = 0; } // ISO_OFFSET_DATE, dutch format
			else if	(this.matches(/\d{4}-\d{2}-\d{2}T(Z|\+\d{2}:\d{2})/))						{ format = 'YYYY-MM-DDZ'; precision = 0; } // ISO_OFFSET_DATE
			else if	(this.matches(/\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}/))						{ format = 'DD-MM-YYYY HH:mm:ss'; precision = 2; } // dateTimeFormat
			else if (this.matches(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/))						{ format = 'YYYY-MM-DD HH:mm:ss'; precision = 2; } // dbDateTimeFormat
			else if (this.matches(/\d{2}-\d{2}-\d{4} \d{2}:\d{2}/))								{ format = 'DD-MM-YYYY HH:mm'; precision = 1; } // dateTimeFormatWithoutSeconds
			else if (this.matches(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}/))								{ format = 'YYYY-MM-DD HH:mm'; precision = 1; } // dbDateTimeFormatWithoutSeconds
			else if (this.matches(/\d{2}-\d{2}-\d{4}/))											{ format = 'DD-MM-YYYY'; precision = 0; } // dateFormat
			else if (this.matches(/\d{4}-\d{2}-\d{2}/))											{ format = 'YYYY-MM-DD'; precision = 0; } // dbDateFormat
			else if (this.matches(/\d{2}:\d{2}:\d{2}/))											{ format = 'HH:mm:ss'; precision = -1; } // timeFormat
			else if (this.matches(/\d{2}:\d{2}/))												{ format = 'HH:mm'; precision = -1; } // timeFormatWithoutSeconds
			else																				{ return moment.invalid(); }

			var ret																				= options.utc && precision != 0 && precision != 3 ? moment.utc(this, format) : moment(this, format);

			if (precision == 3 && options.utc)													{ ret = ret.utc(); }

			if		(options.roundMethod == 'up')
			{
				if		(precision == 1)														{ ret = ret.endOf('second'); }
				else if (precision == 0)														{ ret = ret.endOf('day'); }
			}
			else if (options.roundMethod == 'down')
			{
				if		(precision == 1)														{ ret = ret.startOf('second'); }
				else if (precision == 0)														{ ret = ret.startOf('day'); }
			}

			return ret;
		}
		else																					{ throw new Error('MomentJS library not found!'); }
	};
})();

function parseIntSafe(n, d)
{
	if (!d)								{ d = 0; }

	var res								= null;

	try									{ res = parseInt(n); }
	catch (ex)							{ /* no problem */ }

	return (res == null || isNaN(res)) ? 0 : res;
}

function parseFloatSafe(n, d)
{
	if (!d)								{ d = 0.00; }

	var res								= null;

	try									{ res = parseFloat(n); }
	catch (ex)							{ /* no problem */ }

	return (res == null || isNaN(res)) ? 0.00 : res;
}

(function()
{
	if (!Node.prototype.replaceNewlines)
	{
		Node.prototype.replaceNewlines = function(replacement)
		{
			var pos, preContent, postContent;

			for (var i = 0; i < this.childNodes.length; i++)
			{
				if (this.childNodes[i] instanceof Text)
				{
					pos													= this.childNodes[i].textContent.indexOf('\n');

					if (pos > -1)
					{
						preContent										= this.childNodes[i].textContent.substring(0, pos);
						postContent										= this.childNodes[i].textContent.substring(pos + 1);

						this.childNodes[i].textContent					= postContent;

						if (preContent != '')							{ this.insertBefore(document.createTextNode(preContent), this.childNodes[i]); }

						this.insertBefore(document.createElement('br'), this.childNodes[i + (preContent == '' ? 0 : 1)]);
					}
				}
				else if (this.childNodes[i] instanceof Node)			{ this.childNodes[i].replaceNewlines(); }
			}
		};
	}
})();

var actionLock, lastActionHero;

(function()
{
	var actionlock;

	function getCurrentTimestamp()										{ return new Date().getTime(); }

	function getValue(list, key)
	{
		if (list)														{ return list[key]; }
		else															{ return undefined; }
	}

	function setValue(list, key, value)
	{
		if (!list)														{ list = {}; }

		list[key]														= value;

		return list;
	}

	function clearValue(list, key)
	{
		if (list)														{ delete list[key]; }

		var found														= false;
		for (var a in list)												{ found = true; break; }

		if (!found)														{ list = null; }

		return list;
	}

	/* ActionLock*/
	function hasLock(lockName)
	{
		if (actionlock && actionlock[lockName])
		{
			var ret														= getValue(actionlock, lockName) && getValue(actionlock, lockName) > getCurrentTimestamp();

			if (!ret)													{ actionlock = clearValue(actionlock, lockName); }

			return ret;
		}
		else															{ return false; }
	}

	/**
	 * Usage: actionLock(lockName, delayInMillis)
	 *
	 * Locks an action from being performed for a given amount of time.
	 *
	 * @param lockName A unique name for the lock. Note that this isn't shared between frames.
	 * @param delayInMillis A delay in milliseconds for which to maintain the lock.
	 * @returns True if the action maybe performed, false if the action should be aborted.
	 */
	actionLock = function(lockName, delayInMillis)
	{
		if (hasLock(lockName))											{ return false; }
		else
		{
			if (delayInMillis > 0)										{ actionlock = setValue(actionlock, lockName, getCurrentTimestamp() + delayInMillis); }

			return true;
		}
	}

	/* Last Action Hero(TM) */
	var counters;
	var locked;

	/**
	 * Usage: lastActionHero(lockName, callback, atomic, delayInMillis)
	 *
	 * Executes a provided function after at least delayInMillis, aborting if the same lockName
	 * is reused.
	 *
	 * Used to prevent an action from being executed multiple times.
	 *
	 * @param lockName A unique name for the lock. Note that this isn't shared between frames.
	 * @param callback The function to execute after the delay has expired.
	 * @param atomic True to wait until the function has finished executing before clearing the lock. If this is false only the initial delay is locked.
	 * @param delayInMillis A delay in milliseconds for which to maintain the lock.
	 */
	lastActionHero = function(lockName, callback, atomic, delayInMillis)
	{
		if (typeof(delayInMillis) == "undefined")						{ delayInMillis = 250; } // 250ms
		if (typeof(atomic) == "undefined")								{ atomic = true; }

		var c															= parseIntSafe(getValue(counters, lockName)) + 1;

		counters														= setValue(counters, lockName, c);

		setTimeout(function()
		{
			if (c == getValue(counters, lockName))
			{
				if (atomic && getValue(locked, lockName) === true)		{ lastActionHero(lockName, callback, atomic, delayInMillis); }
				else
				{
					if (atomic)											{ locked = setValue(locked, lockName, true); }

					callback(function()
					{
						if (atomic)										{ locked = clearValue(locked, lockName); }

						if ((atomic || c == getValue(counters, lockName)) && c > 1024)
						{
							counters									= clearValue(counters, lockName);
						}
					});
				}
			}
		}, delayInMillis);
	}
})();

var download;

(function()
{
	'use strict';

	var fallback = function(url)
	{
		// Try a new window first, because we don't know if Content-Disposition is used.
		try																{ window.open(url); }
		catch (ex)														{ window.location.href = url; }
	};

	var dispatchEvent = function(type, attrs)
	{
		var e;

		if (document.createEvent)
		{
			e															= document.createEvent('Event');

			e.initEvent(type, true, true);
		}
		else															{ e = new Event(type); }

		e.data															= attrs;

		window.dispatchEvent(e);
	}

	/**
	 * Usage: download(url)
	 *
	 * Downloads the file in the background. Falls back to a regular file download if AJAX is unavailable.
	 *
	 * @param url The URL of the file to download.
	 */
	download = function(url, fileName)
	{
		try
		{
			if (url instanceof Blob)
			{
				var blob												= url;

				dispatchEvent('beforeDownload', { 'blob': blob, 'fileName': fileName });

				if		(window.navigator.msSaveOrOpenBlob) // IE
				{
					window.navigator.msSaveOrOpenBlob(blob, fileName);

					dispatchEvent('afterDownload', { 'blob': blob, 'fileName': fileName });
				}
				else if (/constructor/i.test(window.HTMLElement) || window.safari) // Safari
				{
					var reader											= new FileReader();
					reader.onloadend									= function()
					{
						var url											= reader.result.replace(/^data:[^;]*;/, 'data:attachment/file;');

						window.open(url);

						dispatchEvent('afterDownload', { 'blob': blob, 'fileName': fileName });
					}

					reader.readAsDataURL(blob);
				}
				else
				{
					var downloadUrl										= URL.createObjectURL(blob);
					var a												= document.createElement('a');

					a.onclick											= function(){ document.body.removeChild(a); };
					a.download											= fileName;
					a.href												= downloadUrl;

					document.body.appendChild(a);
					a.click();

					// Release the existing object URL, and the anchor
					setTimeout(function()
					{
						URL.revokeObjectURL(downloadUrl);

						dispatchEvent('afterDownload', { 'blob': blob, 'fileName': fileName });
					}, 100);
				}
			}
			else
			{
				dispatchEvent('beforeDownload', { 'url': url });

				var xhr													= new XMLHttpRequest();

				xhr.open('GET', url, true);

				xhr.responseType										= 'blob';

				xhr.onload = function(e)
				{
					if (this.status == 200)
					{
						var contentDisposition							= this.getResponseHeader('Content-Disposition');
						var fileName;

						if (contentDisposition && contentDisposition.indexOf('filename='))
						{
							fileName									= contentDisposition.right('filename=');
							if (fileName.indexOf('"') > -1)				{ fileName = fileName.right('"').left('"'); }
						}
						else											{ fileName = url.backwardsRight('/'); }

						download(new Blob([ this.response ], { type: this.response.type }), fileName);
					}
					else												{ fallback(url); }

					dispatchEvent('afterDownload', { 'url': url });
				}

				xhr.send();
			};

		}
		catch (ex)														{ console.log(ex); fallback(url); throw ex; }
	};
})();

var saveSelection, restoreSelection;

(function()
{
	saveSelection = function(containerEl, wnd)
	{
		if (!wnd)													{ wnd = window; }

		var range													= wnd.getSelection().getRangeAt(0);
		var preSelectionRange										= range.cloneRange();

		preSelectionRange.selectNodeContents(containerEl);
		preSelectionRange.setEnd(range.startContainer, range.startOffset);

		var start													= preSelectionRange.toString().length;

		return {
			start: start,
			end: start + range.toString().length
		};
	};

	restoreSelection = function(containerEl, savedSel, wnd)
	{
		if (!wnd)													{ wnd = window; }

		var charIndex												= 0;
		var range													= wnd.document.createRange();

		range.setStart(containerEl, 0);
		range.collapse(true);

		var nodeStack												= [containerEl];
		var node
		var foundStart												= false;
		var stop													= false;
		var nextCharIndex;

		while (!stop && (node = nodeStack.pop()))
		{
			if (node.nodeType == 3)
			{
				nextCharIndex										= charIndex + node.length;

				if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex)
				{
					range.setStart(node, savedSel.start - charIndex);

					foundStart										= true;
				}

				if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex)
				{
					range.setEnd(node, savedSel.end - charIndex);
					stop											= true;
				}

				charIndex											= nextCharIndex;
			}
			else
			{
				var i												= node.childNodes.length;

				while (i--)											{ nodeStack.push(node.childNodes[i]); }
			}
		}

		var sel														= wnd.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	};
})();

(function()
{
	if (window.navigator.userAgent.indexOf('Trident/') == -1)	{ return; }

	var version													= parseInt(window.navigator.userAgent.right('Trident/').left(' ').replace(/;/g, ''));

	/*
	 * We work with Trident version, NOT IE version!!
	 *
	 * Trident 4 = IE 8
	 * Trident 5 = IE 9
	 * Trident 6 = IE 10
	 * Trident 7 = IE 11
	 *
	 */

	// (ALL) Emulation of the "autofocus" attribute.
	$(window).on('load', function()
	{
		var r													= 0;

		var setFocus = function()
		{
			$('[autofocus]:not(:focus)').eq(0).focus();

			// document.activeElement may be null in case of third-party libraries such as AngularJS, which hide the content (and therefore make focusing impossible).
			if (++r <= 5 && document.activeElement == null)		{ setTimeout(setFocus, 100); }
		};

		setFocus();
	});
})();

